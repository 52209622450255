import "../../common/polyfills"
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import GoogleMaps from './Components/GoogleMaps';

function MountGoogleMaps(props: any, el: Element) {
    ReactDOM.render(
        <GoogleMaps {...props} />
        , el);
}

(window as any).MountGoogleMaps = MountGoogleMaps;


