import * as React from "react";
import { useEffect } from "react";
import "../Style/GoogleMaps.scss";

export interface IGoogleMapsProps {
    coordinates: string;
    markerName: string;
    header: string;
    description: string;
    mapStylesJsonString?: string
    mapType?: google.maps.MapTypeId | string
}

const getMapTypeId = (googleMapType: string) => {
    switch (googleMapType) {
        case "google.maps.MapTypeId.ROADMAP":
            return google.maps.MapTypeId.ROADMAP
        case "google.maps.MapTypeId.HYBRID":
            return google.maps.MapTypeId.HYBRID
        case "google.maps.MapTypeId.TERRAIN":
            return google.maps.MapTypeId.TERRAIN
        case "google.maps.MapTypeId.SATELLITE":
            return google.maps.MapTypeId.SATELLITE
        default:
            return null
    }
}
export default (props: IGoogleMapsProps) => {
    const mapStyles = props.mapStylesJsonString ? JSON.parse(props.mapStylesJsonString) as google.maps.MapTypeStyle[] : null
    const mapTypeId = props.mapType 
    useEffect(() => {
        const splitCoordinates = props.coordinates.split(',');
        const latLng = { lat: Number(splitCoordinates[0]), lng: Number(splitCoordinates[1]) }
        const map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: latLng.lat, lng: latLng.lng },
            zoom: 14,
            styles: mapStyles
        });
        if(getMapTypeId(props.mapType)){
            map.setMapTypeId(getMapTypeId(props.mapType))
        }
        const marker = new google.maps.Marker({
            position: latLng,
            map: map,
            title: props.markerName
        });
    });
    return (
        <div>
            <div className="googleMapsHeader">
                {props.header}
            </div>
            <div className="googleMapsDescription">
                {props.description}
            </div>
            <div id="map" className="googleMapsMap" />
        </div>
    );
};
